import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Alert, Button } from 'reactstrap';
import BlockUi from 'react-block-ui';
import { Form } from '@availity/form';
import { AvOrganizationSelect } from '@availity/select';

import type { Organization } from '@/types';
import { fetchAllNodes, getIsAccountMigrated } from '@/api';
import { IS_PROD, IS_TEST, MFT_PERMISSION, OLD_MFT_PERMISSION } from '@/constants';
import { useAppContext } from '@/context';

import * as yup from 'yup';

type FormValues = {
  org: Organization | undefined;
};

const initialValues: FormValues = { org: undefined };

export const OrgSelect = () => {
  const { setOrganization } = useAppContext();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchHomeDirectory = async (customerId: string) => {
    const path = '/';
    queryClient.prefetchQuery(['nodes', path, customerId], () => fetchAllNodes(path, customerId));
  };

  const handleSubmit = async (values) => {
    if (values.org) {
      setOrganization(values.org as Organization);
      let isMigrated = false;
      const url = `/availity/WebUploadServlet?menuTempl=1606&cacheBust=${Date.now()}&Organization=${values.org.customerId}`;
      setLoading(true);
      try {
        switch (true) {
          case IS_TEST:
            fetchHomeDirectory(values.org.customerId);
            history.push(`/file-manager/${values.org.customerId}`);
            break;
          case IS_PROD:
            window.top.location.hash = `#!/loadApp?appUrl=${encodeURIComponent(url)}`;
            break;
          default: {
            const response = await getIsAccountMigrated(values.org.customerId);
            isMigrated = response.isMailboxMigrated;
            if (isMigrated) {
              fetchHomeDirectory(values.org.customerId);
              history.push(`/file-manager/${values.org.customerId}`);
            } else {
              window.top.location.hash = `#!/loadApp?appUrl=${encodeURIComponent(url)}`;
            }
            break;
          }
        }
      } catch {
        setError('There was an error determining whether your organization has been migrated');
      }
    }
  };

  return (
    <BlockUi blocking={loading}>
      <Alert isOpen={!!error} color="danger">
        {error || 'There was an error fetching your organizations.'}
      </Alert>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={yup.object().shape({
          org: yup.object().required('You must select an organization to continue.'),
        })}
      >
        <AvOrganizationSelect
          className="mb-0"
          required
          id="org"
          name="org"
          label="Select an Organization to manage files"
          parameters={{
            permissionId: [MFT_PERMISSION, OLD_MFT_PERMISSION],
          }}
          permissionIds={[MFT_PERMISSION, OLD_MFT_PERMISSION]}
        />
        <Button block type="submit" color="primary">
          Submit
        </Button>
      </Form>
    </BlockUi>
  );
};
