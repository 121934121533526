import React, { useState } from 'react';
import { Alert, Button, ButtonGroup, Card, Col, Container, Row } from 'reactstrap';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BlockUi from 'react-block-ui';
import AvIcon from '@availity/icon';
import { Form, Field } from '@availity/form';
import { Phone } from '@availity/phone';
import * as yup from 'yup';
import Authorize from '@availity/authorize';
import ChangePassword, { ChangePasswordForm } from '@availity/change-password';

import { editUser, getUser, updateUserPassword, resetUserPassword, AdminChangePasswordApi, passwordConditions, updatePasswordSchema } from '@/api';
import { PublicKeys } from './PublicKeys';
import { MBX, MFT_SUPER_USER_PERMISSON } from '../../../constants';

// Password modes
const UPDATE = 'UPDATE';
const RESET = 'RESET';

const onHandleSubmit = ({ result, setError, setSuccess }) => {
  if (Array.isArray(result.errors) && result.errors.length > 0) {
    setError(`An error occurred changing your password: ${result.errors[0].message}`);
    setSuccess(null);
  } else if (!result.updateSuccessful) {
    // This is the only situation that can't be caught by the client. Every other password rule should be handled client side before it ever gets to the server
    if (result.warnings.authenticated === false) {
      setError("An error occurred changing your password: The user's current password is incorrect");
    } else {
      setError('An error occurred changing your password');
    }
    setSuccess(null);
  } else {
    setError(null);
  }
};

const ValueDisplay = ({ label, value, className }: { label: string; value: string | null; className?: string }) => {
  return (
    <div className={className}>
      <dt>{label}</dt>
      <dd>{value}</dd>
    </div>
  );
};

const schema = yup.object().shape({
  email: yup.string().email(),
  phone: yup.string(),
  notes: yup.string().max(500, 'Notes must be 500 characters or less'),
  publicKeys: yup.array().of(yup.string()),
});

// Password rules https://gitlab.com/Availity/teams/mft/modules/mft-api/-/blob/master/src/users/passwords/password.service.ts#L80

const resetPasswordSchema = yup.object().shape({
  username: yup.string(),
});

export const UserInfo = ({
  username,
  setSearchType,
  setSecondaryMbxType,
  setValuesCopy,
  submitFormCopy,
}: {
  username: string;
  setSearchType: (searchType: string) => void;
  setSecondaryMbxType: (value: any) => void;
  setValuesCopy: (values: any) => void;
  submitFormCopy: (values: any) => void;
}) => {
  const { data: user, isLoading: isLoadingUser } = useQuery(['user-config', username], () => getUser(username), {
    enabled: !!username,
    retry: 1,
  });
  user.publicKeys = user.publicKeys || [];



  const queryClient = useQueryClient();
  const [isEditMode, setIsEditMode] = useState(false);
  const [changePasswordMode, setChangePasswordMode] = useState('');
  const [newPassword, setNewPassword] = useState(null);
  const [showPwResetAlert, setShowPwResetAlert] = useState(false);

  const {
    mutate: handleEditUser,
    isLoading: isLoadingEditUser,
    isError: isErrorEditUser,
    isSuccess: isSuccessEditUser,
    reset: resetEditUser,
  } = useMutation(editUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(['user-config', username]);
    },
  });

  const {
    // mutate: handleUpdateUserPassword,
    isLoading: isLoadingUpdateUserPassword,
    // isError: isErrorUpdateUserPassword,
    // reset: resetUpdateUserPassword,
  } = useMutation(updateUserPassword, {
    onSuccess: () => {
      setShowPwUpdateAlert(true);
    },
  });

  const {
    mutate: handleResetUserPassword,
    isError: isErrorResetUserPassword,
    reset: resetResetUserPassword,
  } = useMutation(resetUserPassword, {
    onSuccess: (data) => {
      setNewPassword(data.newPassword);
    },
  });

  const searchForMailbox = async (event) => {
    const { value } = event.target;

    if (value) {
      const [mbxType, secondaryMbxType, mbx] = value.split('/');
      setValuesCopy({ searchType: MBX, mbxType, secondaryMbxType, mbx });
      setSearchType(MBX);
      setSecondaryMbxType(secondaryMbxType);
      submitFormCopy();
    }
  };

  const handleChangePasswordModeClick = () => {
    if (changePasswordMode) {
      setChangePasswordMode('');
    } else {
      setIsEditMode(false);
      setChangePasswordMode(UPDATE);
    }
  };

  const handleSetIsEditModeClick = () => {
    if (isEditMode) {
      setIsEditMode(false);
    } else {
      setChangePasswordMode('');
      setIsEditMode(true);
    }
  };

  const changePasswordApi = new AdminChangePasswordApi({}, user.username);

  return (
    <BlockUi blocking={isLoadingEditUser || isLoadingUser || isLoadingUpdateUserPassword} tag={Card} body>
      <Container fluid="sm">
        <Alert isOpen={!!isErrorEditUser} color="danger" toggle={() => resetEditUser()}>
          There was an error updating the user
        </Alert>
        <Alert isOpen={!!isErrorResetUserPassword} toggle={() => resetResetUserPassword()} color="danger">
          There was an error resetting the user password
        </Alert>
        <Alert color="success" isOpen={showPwResetAlert} toggle={() => setShowPwResetAlert(false)}>
          Password has been reset successfully! Your new password is: {newPassword}
        </Alert>
        {changePasswordMode ? (
          <>
            <h5>How would you like to change the user password?</h5>
            <ButtonGroup className="mb-3" name="changePasswordMode">
              <Button
                color="primary"
                outline
                active={changePasswordMode === UPDATE}
                onClick={(event) => setChangePasswordMode(event.target.value)}
                name="changePasswordMode"
                value={UPDATE}
              >
                Update
              </Button>
              <Button
                color="primary"
                outline
                active={changePasswordMode === RESET}
                onClick={(event) => setChangePasswordMode(event.target.value)}
                name="changePasswordMode"
                value={RESET}
              >
                Reset
              </Button>
            </ButtonGroup>
          </>
        ) : null}
        {changePasswordMode === UPDATE ? (
          <div className="mb-3">
            <ChangePassword resource={changePasswordApi} schema={updatePasswordSchema(user.username)} conditions={passwordConditions(user.username)}>
              <ChangePasswordForm
                onHandleSubmit={onHandleSubmit}
                header={<h4>Update User Password</h4>}
                maxLength={null}
              />
            </ChangePassword>
          </div>
        ) : null}
        {changePasswordMode === RESET ? (
          <div className="mb-3">
            <h4>Reset User Password</h4>
            <Form
              initialValues={{ username: user.username }}
              validationSchema={resetPasswordSchema}
              onSubmit={(values) => {
                handleResetUserPassword(
                  {
                    username: values.username,
                  },
                  {
                    onSuccess: () => {
                      setShowPwResetAlert(true);
                      setChangePasswordMode('');
                    },
                  }
                );
              }}
            >
              <Field
                label="User Name"
                aria-readonly
                readOnly
                name="username"
                aria-labelledby="username"
                value={user.username}
              />
              <Button type="submit" color="primary">
                Submit
              </Button>
            </Form>
          </div>
        ) : null}

        {user && (
          <Form
            // Null input values throw a warning from react, so we default any null values with empty strings
            initialValues={Object.keys(user).reduce((accum, key) => {
              if (user[key] === null) {
                accum[key] = '';
              } else {
                accum[key] = user[key];
              }
              return accum;
            }, {})}
            validationSchema={schema}
            onSubmit={(values) => {
              handleEditUser(
                {
                  username: user.username,
                  email: values.email,
                  phone: values.phone,
                  notes: values.notes,
                  publicKeys: values.publicKeys?.map((pk) => pk.trim()),
                },
                {
                  onSuccess: () => {
                    setIsEditMode(false);
                  },
                }
              );
            }}
          >
            <Alert color="success" isOpen={!!isSuccessEditUser} toggle={() => resetEditUser()}>
              User updated!
            </Alert>
            <Alert color="danger" isOpen={!!isErrorEditUser} toggle={() => resetEditUser()}>
              Oops! There was an error updating the user. Please try again.
            </Alert>
            <Row className="mb-2">
              <Col className="d-flex align-items-center justify-content-between">
                <h3 className="h4">{user.username}</h3>
                <Authorize permissions={[MFT_SUPER_USER_PERMISSON]} unauthorized={null}>
                  <ButtonGroup vertical className="float-right">
                    {isEditMode && (
                      <Button className="mx-1" color="primary" type="submit">
                        Save
                      </Button>
                    )}
                    <Button onClick={handleSetIsEditModeClick} className="mx-1">
                      {isEditMode ? 'Cancel' : 'Edit'}
                      <AvIcon name={isEditMode ? 'cancel' : 'edit'} />
                    </Button>
                  </ButtonGroup>

                  <ButtonGroup vertical className="float-right">
                    <Button
                      className="mx-1"
                      onClick={() => {
                        handleEditUser({ username: user.username, accountLocked: !user.accountLocked });
                      }}
                    >
                      {user.accountLocked ? 'Unlock Account' : 'Lock Account'}
                      <AvIcon name={user.accountLocked ? 'lock-open' : 'lock'} />
                    </Button>
                    <Button onClick={handleChangePasswordModeClick} className="mx-1">
                      {changePasswordMode ? 'Cancel' : 'Change Password'}
                      <AvIcon name={changePasswordMode ? 'cancel' : 'credentialing'} />
                    </Button>
                    <Button
                      onClick={() => {
                        handleEditUser({ username: user.username, passwordExpired: !user.passwordExpired });
                      }}
                      className="mx-1"
                    >
                      {user.passwordExpired ? 'Unexpire Password' : 'Expire Password'}
                      <AvIcon name={user.passwordExpired ? 'cancel' : 'clock'} />
                    </Button>
                  </ButtonGroup>
                </Authorize>
              </Col>
            </Row>

            <h4>User Information</h4>
            <Row>
              <Col>
                <dt id="email-contact">Email Contact</dt>
                <dd>{isEditMode ? <Field name="email" aria-labelledby="email-contact" /> : user.email}</dd>
              </Col>
              <Col>
                <dt id="phone-contact">Phone Contact</dt>
                <dd>{isEditMode ? <Phone name="phone" aria-labelledby="phone-contact" /> : user.phone}</dd>
              </Col>
            </Row>
            <h4 className="mt-3">Account Information</h4>
            <Row>
              <Col>
                <ValueDisplay label="Created" value={user.createdAt} />
              </Col>
              <Col>
                <ValueDisplay label="Last Updated" value={user.updatedAt} />
              </Col>
            </Row>
            <Row>
              <Col>
                <ValueDisplay label="Last Login" value={user.lastLogin || 'Never'} />
              </Col>
              <Col>
                <ValueDisplay label="Account Locked?" value={user.accountLocked ? 'Yes' : 'No'} />
              </Col>
            </Row>
            {user.isMigrated ? (
              <Row>
                <Col>
                  <ValueDisplay label="Migrated" value={user.isMigrated ? 'Yes' : 'No'} />
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col>
                <dt id="notes-label">Notes</dt>
                <dd>
                  {isEditMode ? (
                    <Field name="notes" aria-labelledby="notes-label" type="textarea" rows={3} />
                  ) : (
                    user.notes
                  )}
                </dd>
              </Col>
            </Row>
            <Row>
              <Col>
                <PublicKeys className="mb-2" labelTag="dt" user={user} isEditMode={isEditMode} />
              </Col>
            </Row>
            <Row>
              <Col>
                <dt className="h4 mt-3">Mailboxes</dt>
                {/* TODO: add pagination */}
                <dl>
                  {user.mailboxes && user.mailboxes.length > 0
                    ? user.mailboxes.map((mbx) => (
                      <dd key={mbx.id}>
                        {[...mbx.target].filter((ch) => ch === '/').length > 1 ? (
                          <Button className="pl-0 py-0" onClick={searchForMailbox} value={mbx.target} color="link">
                            {mbx.target}
                          </Button>
                        ) : (
                          mbx.target
                        )}
                      </dd>
                    ))
                    : 'No mailboxes associated with this user'}
                </dl>
              </Col>
            </Row>
          </Form>
        )}
      </Container>
    </BlockUi>
  );
};
