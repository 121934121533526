import React, { useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import PageHeader from '@availity/page-header';
import { BreadcrumbItem, Breadcrumb, Button } from 'reactstrap';
import HelpProvider, { Help, triggerFieldHelp } from '@availity/help';
import Icon from '@availity/icon';
import { useMutation } from '@tanstack/react-query';

import { logout } from '@/api';
import { useAppContext } from '@/context';

const helpId = '028fff23-70fe-49bb-a94b-9a56c46146f4';

const TheHelp = () => {
  return (
    <HelpProvider>
      <Help type="provider" id={helpId}>
        <Button onClick={() => triggerFieldHelp(helpId)} color="link">
          Learn more
          <Icon name="angle-double-right" />
        </Button>
      </Help>
    </HelpProvider>
  );
};

/**
 * Breadcrumb item using react-router-dom Link
 */
const Crumb = ({ active, children, route }: { active?: boolean; children: React.ReactNode; route: string }) => {
  return (
    <BreadcrumbItem active={active} tag={Link} to={route}>
      {children}
    </BreadcrumbItem>
  );
};

type CrumbConfig = { label: string; route: string };

const Breadcrumbs = ({ crumbs }: { crumbs: CrumbConfig[] }) => {
  return (
    <Breadcrumb>
      {crumbs.map(({ label, route }, i) => (
        <Crumb key={label} active={i === crumbs.length - 1} route={route}>
          {label}
        </Crumb>
      ))}
    </Breadcrumb>
  );
};

/**
 * Header used for the standard view inside the portal
 */
export const Header = () => {
  return <PageHeader appName="Send and Receive Files" renderRightContent={TheHelp} />;
};

/**
 * Header used for the admin screens
 */
export const AdminHeader = () => {
  const isSearchRoute = useRouteMatch('/admin-dashboard/search');
  const isFileRestoreRoute = useRouteMatch('/admin-dashboard/file-restore');
  const isCreateMbxRoute = useRouteMatch('/admin-dashboard/create-mbx');

  const crumbs = [
    // { label: 'Home', route: '/' },
    { label: 'Availity Administration', route: '/admin-dashboard' },
  ];

  if (isSearchRoute) {
    crumbs.push({ label: 'Search', route: '/admin-dashboard/search' });
  } else if (isFileRestoreRoute) {
    crumbs.push({ label: 'File Restore', route: '/admin-dashboard/file-restore' });
  } else if (isCreateMbxRoute) {
    crumbs.push({ label: 'Create Mailbox', route: '/admin-dashboard/create-mbx' });
  }

  return <PageHeader appName="Availity Administration" crumbs={<Breadcrumbs crumbs={crumbs} />} />;
};

/**
 * Logs the user out when clicked
 */
const LogoutButton = () => {
  const { setIsLoggedIn, setIsLoggingOut } = useAppContext();

  const { mutate: handleLogout, isLoading } = useMutation(logout, {
    onSettled: () => {
      setIsLoggedIn(false);
      setIsLoggingOut(false);
    },
  });


  useEffect(() => {
    setIsLoggingOut(isLoading);
  }, [isLoading, setIsLoggingOut]);

  const handleClick = async () => {
    handleLogout();
  };

  return <Button onClick={handleClick}>Logout</Button>;
};

const UpdatePasswordButton = () => {
  const { setPasswordExpired } = useAppContext();

  const handleClick = async () => {
    setPasswordExpired(true);
  };
  return <Button onClick={handleClick}>Update Password</Button>;
}


/**
 * Header for the external view of the app
 */
export const ExternalHeader = () => {
  const { isLoggedIn, isPasswordExpired } = useAppContext();

  if (!isLoggedIn || isPasswordExpired) return null;

  const RenderRightContent = () => {
    return (
      <div>
        <UpdatePasswordButton />
        &nbsp;&nbsp;
        <LogoutButton />
      </div>
    );
  }

  return <PageHeader appName="Send and Receive Files" crumbs={<></>} renderRightContent={RenderRightContent} />;
};
