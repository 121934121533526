import React, { useState } from 'react';
import { Alert, Col, Row, Card, CardBody, CardHeader } from 'reactstrap';
import { useEffectAsync } from '@availity/hooks';
import { getPasswordExpired, getIsAuthenticated } from '@/api';
import { useMutation } from '@tanstack/react-query';
import BlockUi from 'react-block-ui';

import type { Folder } from '@/types';
import { useAppContext } from '@/context';

import { ExternalFileSystemTree, ExternalFileViewer, Login } from './components';

const ROOT = '/';
const initialFolder: Folder = { data: ROOT, key: ROOT, label: 'Home', leaf: false };

const FileViewer = () => {
  const [selectedFolder, setSelectedFolder] = useState(initialFolder);
  const [hasFolderError, setHasFolderError] = useState(false);

  return (
    <>
      <Alert className="mb-3" isOpen={hasFolderError} color="danger">
        There was an error retrieving the available folders.
      </Alert>
      <Row style={{ minHeight: '800px' }} id="file-viewer-row">
        <Col xs="3">
          <Card>
            <CardHeader>
              <h4 className="h5">Folders</h4>
            </CardHeader>
            <CardBody>
              <ExternalFileSystemTree
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}
                setHasFolderError={setHasFolderError}
                folder={initialFolder}
                isRoot
              />
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <ExternalFileViewer selectedFolder={selectedFolder} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export const External = () => {
  const { isLoggedIn, setIsLoggedIn, setUsername, isLoggingOut, isPasswordExpired, setPasswordExpired, setLoggedOutModalIsOpen } = useAppContext();

  const {
    mutate: handleGetPasswordExpired,
    isLoading: isExpiredLoading,
  } = useMutation(getPasswordExpired, {
    onSettled: (response = false) => {
      setPasswordExpired(response);
    },
    onError: () => {
      setPasswordExpired(true);
    },
  });


  const { mutate: handleCheckAuthenticatedStatus, isLoading: isAuthenticatedLoading } = useMutation(getIsAuthenticated, {
    onSettled: (response) => {
      setIsLoggedIn(response?.data?.authenticated);
      if (response?.data?.authenticated) {
        handleGetPasswordExpired();
        setUsername(response?.data?.username)
      }
    },
    onError: () => {
      setIsLoggedIn(false);
      setLoggedOutModalIsOpen(true);
    },
  });

  useEffectAsync(() => {
    handleCheckAuthenticatedStatus();
  }, [isLoggedIn]);

  if (isAuthenticatedLoading || isExpiredLoading) return null;
  return <BlockUi blocking={isLoggingOut}>{isLoggedIn && !isPasswordExpired ? <FileViewer /> : <Login />}</BlockUi>;
};
